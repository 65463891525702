import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { cloneDeep, isEqual } from 'lodash-es';
import { SharedModule } from '../../shared/shared.module';

@Component({
	standalone: true,
	imports: [SharedModule],
	selector: 'filter-select',
	templateUrl: './filter-select.component.html',
})
export class FilterSelectComponent implements OnChanges {
	@Input() filter;

	@Output() filterChange = new EventEmitter<any>();
	@Output() update = new EventEmitter<void>();

	@Input() options;

	currentFilter;

	ngOnChanges(): void {
		if (!this.filter || this.filter === '') {
			this.clearFilter();
		}
	}

	close(): void {
		this.clearFilter();
		this.runupdate();
	}

	runupdate(): void {
		if (this.filter === '') {
			delete this.filter;
		}

		if (!isEqual(this.currentFilter, this.filter)) {
			this.currentFilter = cloneDeep(this.filter);

			// FILTER OUTPUT
			this.filterChange.emit(this.filter);
			this.update.emit();
		}
	}

	setValue(): void {
		this.runupdate();
	}

	clearFilter(): void {
		delete this.filter;
	}
}
