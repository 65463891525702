@if (!readonly) {
	<div>
		<select class="fm-select" name="formSelect" #formSelect="ngModel" [(ngModel)]="value">
			<option *ngFor="let op of options" [value]="op.id">{{ op.name }}</option>
		</select>
		<span class="input-icons"><i class="fa-light fa-circle-chevron-down"></i></span
		><input-status class="input-status" [obj]="formSelect"></input-status>
	</div>
}
@if (readonly) {
	<div class="field-info" [translate]="model | uppercase"></div>
}
