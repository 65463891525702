<div class="filter-wrap">
	<div class="field-editor">
		<select class="fm-select" [(ngModel)]="filter" (change)="setValue()">
			<option [value]="undefined">All</option>
			<option
				*ngFor="let op of options; trackBy: 'index' | trackByProperty"
				[value]="op.id"
				[translate]="op.name"></option>
		</select>
		<span class="input-icons"><i class="fa-light fa-circle-chevron-down"></i></span>
	</div>
</div>
