<div class="filter-wrap">
	@if (isLoading) {
		<div class="filter-loader">
			<span class="fa fa-circle-notch fa-spin"></span>
		</div>
	}

	@if (!isLoading) {
		@if (search.total === 0) {
			<ul class="filter-options">
				<li class="empty-list">No Items Available</li>
			</ul>
		}

		@if (search.total <= SEARCH_LIMIT) {
			<ul class="filter-options">
				<li
					class="fm-savedfilter"
					*ngFor="let filter of search.list; trackBy: 'id' | trackByProperty"
					(click)="filterClick(filter)"
					[ngClass]="{ active: isActive(filter) }">
					<a [routerLink]="">
						<span class="filter-status"><i class="fa fa-check"></i></span>
						<span class="filter-title" translate="{{ filter.name }}"></span>
					</a>
				</li>
			</ul>
		}

		@if (search.total > SEARCH_LIMIT) {
			<div class="typeahead">
				<div class="field-editor">
					<input
						type="text"
						#searchInput
						class="fm-input"
						placeholder="{{ 'SEARCH' | translate }}"
						matInput
						[formControl]="myControl"
						[matAutocomplete]="auto" />

					<div class="input-loader" *ngIf="isSearching">
						<span class="fa fa-circle-notch fa-spin"></span>
					</div>
					<mat-autocomplete #auto="matAutocomplete" (optionSelected)="setItem($event)">
						<mat-option
							*ngFor="let option of filteredOptions | async"
							[value]="option"
							>{{ option.name | translate }}</mat-option
						>
					</mat-autocomplete>
				</div>

				@if (selected.length) {
					<ul class="filter-list">
						<li class="fm-tag" *ngFor="let item of selected">
							<span [translate]="item.name"></span>
							<div class="remove-tag" (click)="clearItem(item)">
								<i class="fa fa-times"></i>
							</div>
						</li>
					</ul>
				}
			</div>
		}
	}
</div>
