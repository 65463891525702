import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { InputStatusComponent } from '../input-status.component';

@Component({
	standalone: true,
	imports: [CommonModule, FormsModule, InputStatusComponent, TranslateModule],
	selector: 'fm-select',
	templateUrl: './fm-select.component.html',
})
export class FmSelectComponent {
	// bindings
	@Input() value;
	@Input() options;
	@Input() readonly: boolean;

	// required
	form;
	model;

	update(): void {
		// this.ngModel.$setViewValue(this.model);
	}
}
