import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
	standalone: true,
	name: 'base64Image',
})
export class Base64ImagePipe implements PipeTransform {
	constructor(public _sanitizer: DomSanitizer) {}
	transform(value: string): SafeUrl {
		let base64Image;
		if (value) {
			base64Image = this._sanitizer.bypassSecurityTrustResourceUrl(
				'data: image/png;base64,' + value
			);
		}
		return base64Image;
	}
}
